@mixin make-miscellaneous-pricing-form_private {
	
	border-top: 1px solid $gray-lighting;
	padding-top: .625rem;
    &__wrapper {
    	@include clearfix;
        padding-top: .25rem;
    	width: 100%;
        
        &_supplier-info-container {
	    	@include clearfix;
	    	float: left;
	    	width: 50%;
	    	&-bsp {
				width: 100%;
			}
	    	
        	.supplier-info-container {
        		&__supplier-list {
       				float: left;
        			width: 47%;
        			/*&_label {
						color: $brand-color-28;
					}*/
        			&_dropdown-disabled{
						background-color: $gray-lightest;
						cursor: not-allowed;
					}
        		}
        		
        		&__supplier-currency, &__supplier-ref-no, &__room-list,&__currency_rate {
       				float: left;
       				margin-left: 1rem; 
        			width: 20%;
        			
        			&_value {
        				font-size: 1.25rem;
        			}
        			/*&_label {
						color: $brand-color-28;
					}*/
        			&_disabled {
						input {
							background-color: $gray-lightest;
							cursor: not-allowed;
						}
					}
				}
				&__tagging-container {
	
					border: 1px solid #d0d0d0;
					height: 34px;
					
				   .ui-select-highlight {
					 font-weight: bold;
				   }
				   
				   .ui-select-offscreen {
					 clip: rect(0 0 0 0) !important;
					 width: 1px !important;
					 height: 1px !important;
					 border: 0 !important;
					 margin: 0 !important;
					 padding: 0 !important;
					 overflow: hidden !important;
					 position: absolute !important;
					 outline: 0 !important;
					 left: 0px !important;
					 top: 0px !important;
				   }
							   
				   .btn-default-focus {
					 color: $dark;
					 border-color: $brand-color-4;
					 text-decoration: none;
					 outline: 5px auto -webkit-focus-ring-color;
					 outline-offset: -2px;
					 box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
					 height: 34px;
				   }
				   
				   .ui-select-bootstrap .ui-select-toggle {
						width: 100%;
						line-height: 2.5;
						width: 100%;
						padding: 0.5rem 1rem;
						background: none;
						font-size: 0.875rem;
						vertical-align: middle;
						line-height: normal;
				    }
				   
				   .ui-select-bootstrap .ui-select-toggle > .caret {
					 position: absolute;
					 height: 10px;
					 top: 50%;
					 right: 10px;
					 margin-top: -2px;
				   }
				   
				   .input-group > .ui-select-bootstrap.dropdown {
					 position: static;
				   }
				   
				   .input-group > .ui-select-bootstrap > input.ui-select-search.form-control {
					 border-radius: 4px; /* FIXME hardcoded value :-/ */
					 border-top-right-radius: 0;
					 border-bottom-right-radius: 0;
				   }
				   .input-group > .ui-select-bootstrap > input.ui-select-search.form-control.direction-up {
					 border-radius: 4px !important; /* FIXME hardcoded value :-/ */
					 border-top-right-radius: 0 !important;
					 border-bottom-right-radius: 0 !important;
				   }
				   
				   .ui-select-bootstrap > .ui-select-match > .btn{
					 text-align: left !important;
				   }
				   
				   input[type="search"] {
					border: none;
					padding: 0.5rem 1rem;
					background: none;
					font-size: 0.875rem;
					vertical-align: middle;
					line-height: normal;
					width: 100%;
					box-sizing: inherit;
				   }
				  
				   .ui-select-bootstrap > .ui-select-match > .caret {
					 position: absolute;
					 top: 45%;
					 right: 15px;
				   }
				   
				   .ui-select-bootstrap > .ui-select-choices {
					 height: auto;
					 overflow-x: hidden;
					 margin-top: 0px;
					 min-width: 100%;
					 max-width: 130%;
					 max-height: 400px;
					 line-height: 1.5rem;
					 padding: .5rem 1rem;
					 white-space: nowrap;
					 cursor: default;
					 font-weight: normal;
					 font-size: .875rem;
				   }
				   
				   body > .ui-select-bootstrap.open {
					 z-index: 1000; /* Standard Bootstrap dropdown z-index */
				   }
				   
				   .ui-select-multiple.ui-select-bootstrap {
					 height: auto;
					 padding: 3px 3px 0 3px;
				   }
				   
				   .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
					 background-color: transparent !important; /* To prevent double background when disabled */
					 border: none;
					 outline: none;
					 height: 1.666666rem;
					 margin-bottom: 3px;
					 color:$brand-color-5;
				   }
				   
				   .ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
					 font-size: 1.6em;
					 line-height: 0.75;
				   }
				   
				   .ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
					 outline: 0;
					 margin: 0 3px 3px 0;
				   }
				   
				   .ui-select-multiple .ui-select-match-item {
					 position: relative;
				   }
				   
				   .ui-select-multiple .ui-select-match-item.dropping-before:before {
					 content: "";
					 position: absolute;
					 top: 0;
					 right: 100%;
					 height: 100%;
					 margin-right: 2px;
					 border-left: 1px solid $brand-color-3;
				   }
				   
				   .ui-select-multiple .ui-select-match-item.dropping-after:after {
					 content: "";
					 position: absolute;
					 top: 0;
					 left: 100%;
					 height: 100%;
					 margin-left: 2px;
					 border-right: 1px solid $brand-color-3;
				   }
				   
				   .ui-select-bootstrap .ui-select-choices-row>a {
					   display: block;
					   padding: 3px 20px;
					   clear: both;
					   font-weight: 400;
					   line-height: 1.42857143;
					   color: $dark;
					   white-space: nowrap;
				   }
				   
				   .btn-default {
					   color: $brand-color-5;
					   background-color: $light;
				   }
				   
				   .btn {
					   display: inline-table;
				   }
				   
				   .close {
					   float: right;
					   font-weight: 700;
					   opacity: 0.5;
					   cursor: pointer;
				   }
			   }
        	}
        }
        
        &_total-amount-container {
        	@include clearfix;
	    	float: left;
        	margin-bottom:1rem;
	    	width: 100%;
        }
        
        &_pricing-container {
	    	float: left;
	    	width: 100%;
        }
         &_air-fare-info-container {
		   padding: 10px;
		   margin: 10px 0;
		   box-shadow: 0 0 15px $box-shadow-color;
	   }
	   
    }
    
    &__approval-form {
		padding: 0.725rem;
		border: 10px solid $brand-color-3;
		margin-bottom: 0.725rem;
		&_approval-form-header {
			.approval-form-header {
				&__status {
				    border-bottom: 1px solid $brand-color-28;
				    color: $brand-color-28;
				    font-size: 1.25rem;
				    margin-bottom: 0.5rem;
				    padding-bottom: 0.5rem;
				    text-align: center;
				}
				&__client-balance-details {
					text-align: right;
					color: $brand-color-28;
					font-size: 14px;
					font-family: $font-family-number;
					line-height: 1;
				}
			}
		}
	}
	
    &__emd-container {
		@include clearfix();
		border: 1px solid $gray-lighting;
		padding: 0.5rem;
		padding-bottom: 0.925rem;
		width:100%;
		display: table;
		&_header {
			width: 20%;
			float:left;
			color: #55595c;
			font-size: 1.125rem;
		}	
		&_details {
			width: 100%;
			.emd-container {
				@include clearfix();
				width:100%;
				&__emd-amount {
				    width: 50%;
				    float:right;
					&_label {
						float:right;
						font-size: 1rem;
						padding: 0.3125rem;
						color: #087dc2;
						text-align: right;
					}
					&_input {
						float:right;
						.emd-amount {
							display: flex;
							flex-direction: row;
							&__input-errors {
								color:$brand-color-1;
								height: 18px;
							}
						}
					}
				}
				
				&__emd-remarks {
				    @include clearfix();
				    width: 50%;
				    float:left;
					&_label {
						font-size: 1rem;
						padding: 0.3125rem;
						color: #087dc2;
						text-align: right;
						float:left;
					}
					&_value {
						float:left;
						min-height: 36px;
						>textarea {
							height: 36px;
						    min-height: 36px;
						    max-height: 100px;
						    width: 350px;
							border: 1px solid #d0d0d0;
						}
					}
				}
			}
		}
		
		

		
	}
	
	
}

@mixin  make-miscellaneous-pricing-form { 
	@include make-miscellaneous-pricing-form_private; 
}